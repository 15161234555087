import './OrderForm.css';
import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import TextField from '@mui/material/TextField';
import { REQUIRED_ERROR_MSG, EMAIL_ERROR_MSG, PHONE_NUMBER_ERROR_MSG, POST_NUMBER_ERROR_MSG, ERROR_INVALID_EMAIL, ERROR_BOX_ADDRESS } from '../../const';

function FormGroup({ label, name, value, onChange, isRequired = true, isValid = true, onBlur, submitted}) {
    const [errorText, setErrorText] = useState('');
  
    useEffect(() => {
        if (!isValid) {
          if (name === "clientEmail") {
            if(!/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)){
              setErrorText(EMAIL_ERROR_MSG)
            } else{
              setErrorText(ERROR_INVALID_EMAIL);
            }
          } else if (name === "clientPhone" || name === "deliveryphoneNumber") {
            setErrorText(PHONE_NUMBER_ERROR_MSG);
          } else if (name === "companyPostNumber" || name === "deliverypostNumber") {
              setErrorText(POST_NUMBER_ERROR_MSG);
          } else if (isRequired && (value === '' || value === 0)) {
            setErrorText(REQUIRED_ERROR_MSG);
          } else if (name === "deliverystreetAddress") {
            setErrorText(ERROR_BOX_ADDRESS);
          } else {
            setErrorText('');
          }
        } else {
          if (isRequired && (value === '' || value === 0)) {
            setErrorText(REQUIRED_ERROR_MSG);
          } else {
            setErrorText('');
          }
        }
      }, [name, value, isRequired, isValid, submitted]);

      useEffect(() => {
          setErrorText('');
      }, []);

      return (
        <Form.Group className="mb-3" controlId={`input${name}`}>
          <p style={{ marginBottom: "5px" }}>{label}</p>
          <TextField fullWidth className="text-field" type="text" name={name} variant="outlined" required={isRequired}
            onChange={onChange} defaultValue={value} error={!!errorText} helperText={errorText}
            inputProps={{
              style: {
                height: "10px",
              }, 
              onBlur
            }} />
        </Form.Group>
      );
}

export { FormGroup };