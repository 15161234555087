import './OrderForm.css';
import arrow from "../../assets/arrow.png";

function SectionHeader({title}) {
    return (
        <div className='section-header'>
            <img src={arrow} className={"arrow"} alt="arrow"/>
            <h3>{title}</h3>
        </div>
    );
}

export { SectionHeader };