import './App.css';
import {OrderForm} from "./components/OrderForm/OrderForm";
import {Intro} from "./components/Intro";
import { Footer } from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Intro/>
      <OrderForm/>
      <Footer/>
    </div>
  );
}

export default App;
