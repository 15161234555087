import './FaqSection.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


function FaqSection() {
    return (
        <div className='faq-section'>
            {/* Section 1 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Vad ska vara med på underlaget?
                </AccordionSummary>
                <AccordionDetails>
                    <div id='section-1'>
                        <div className='faq-section'>
                        <ul>
                            <li>
                                <h6>Tavelplacering, utrymningsvägar, larmknappar och annan eventuell brandskyddsutrustning.</h6>
                                <p>Tänk på att det endast är larmknappar och utrymningsplatser för rörelsehindrade som behöver redovisas enligt SS 2875:2019. </p>
                            </li>
                            <li>
                                <h6>Trappriktningar måste framgå för ritningar i 3D. </h6>
                            </li>
                            <li>
                                <h6>Markera direkt på underlaget om en yta skall gråmarkeras eller klippas bort. </h6>
                                <p>Gråmarkeringen är framförallt till för ytor utan åtkomst. Ytor innehållandes utrymningsvägar och/eller brandredskap bör ej gråmarkeras.</p>
                            </li>
                        </ul>
                        </div>
                        
                        
                        <div className='image-wrapper'>
                            <img src='/faq/q1.jpg' alt='plan' id='example-plan'/>
                            <p id='file-link'>PDF: <a href={'/documents/instruktion_utrymningsplan.pdf'} target='_blank' rel='noreferrer'>Fullständiga instruktioner för underlag till utrymningsplaner</a></p>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Section 2 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Hur markerar jag ut brandredskap på underlaget?
                </AccordionSummary>
                <AccordionDetails>
                    <p>Följande symboler ska finnas med om de finns i lokalen enligt Svensk Standard.</p>
                    <table>
                        <thead>
                        <tr>
                            <th style={{'width': '30%'}}>Markering</th>
                            <th style={{'width': '40%'}}>Förklaring</th>
                            <th style={{'width': '30%'}}>Symbol</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {/* <td rowSpan={4}><i></i></td> */}
                            <td><img src='/faq/q2_l_1.svg' alt='markering' style={{'marginLeft': '15%'}}/></td>
                            <td>Här är du (liggande, stående)</td>
                            <td><img src='/faq/q2_r_1.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_2.svg' alt='markering'/></td>
                            <td>Larmknapp</td>
                            <td><img src='/faq/q2_r_2.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_3.svg' alt='markering'/></td>
                            <td>Återsamlingsplats</td>
                            <td><img src='/faq/q2_r_3.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_4.svg' alt='markering'/></td>
                            <td>Utrymningsplats för rörelsehindrade</td>
                            <td><img src='/faq/q2_r_4.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td colSpan={3} style={{'padding': '15px 0 5px 0', 'textAlign': 'left'}}>Följande symboler måste inte redovisas enligt Svensk Standard.</td>
                        </tr>
                        <tr>
                            <td style={{'paddingTop': '5px'}}><img src='/faq/q2_l_5.svg' alt='markering'/></td>
                            <td>Hjärtstartare</td>
                            <td><img src='/faq/q2_r_5.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_6.svg' alt='markering'/></td>
                            <td>Inomhusbrandpost</td>
                            <td><img src='/faq/q2_r_6.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_7.svg' alt='markering'/></td>
                            <td>Brandsläckare</td>
                            <td><img src='/faq/q2_r_7.svg' alt='symbol'/></td>
                        </tr>
                        <tr style={{'borderBottom':'solid 1px black'}}>
                            <td><img src='/faq/q2_l_8.svg' alt='markering'/></td>
                            <td>Första hjälpen</td>
                            <td><img src='/faq/q2_r_8.svg' alt='symbol'/></td>
                        </tr>
                        <tr>
                            <td style={{'paddingTop': '10px'}}><img src='/faq/q2_l_wx.svg' alt='markering'/></td>
                            <td>Utrymning via fönster</td>
                            <td><img src='/faq/q2_r_wx.svg' alt='markering'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_arrow.svg' alt='markering'/></td>
                            <td>Utrymningsväg </td>
                            <td><img src='/faq/q2_r_arrow.svg' alt='markering'/></td>
                        </tr>
                        <tr>
                            <td><img src='/faq/q2_l_line.svg' alt='markering'/></td>
                            <td>Gångväg till utrymningsväg</td>
                            <td><img src='/faq/q2_r_line.svg' alt='markering'/></td>
                        </tr>
                        </tbody>
                    </table>
                </AccordionDetails>
            </Accordion>

            {/* Section 3 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Hur markerar jag ut trappriktningar?
                </AccordionSummary>
                <AccordionDetails>
                    <div id='section-3'> 
                        <table>
                            <thead>
                                <tr>
                                    <th style={{'width': '20%'}}>Markering</th>
                                    <th style={{'width': '20%'}}>Förklaring</th>
                                    <th style={{'width': '20%'}}>Resultat</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><img src='/faq/q3_l_plus.svg' alt='plus icon'/></td>
                                    <td>Trappa upp från aktuellt plan</td>
                                    <td><img style={{'maxHeight': '3lh'}} src='/faq/q3_stair_up.svg' alt='stairs up'/></td>
                                </tr>
                                <tr>
                                    <td><img src='/faq/q3_l_minus.svg' alt='minus icon'/></td>
                                    <td>Trappa ner från aktuellt plan</td>
                                    <td><img style={{'maxHeight': '3lh'}} src='/faq/q3_stair_down.svg' alt='stairs down'/></td>
                                </tr>
                            </tbody>
                        </table>
                        <p><i>Trappriktning och utrymningsriktning är ej samma sak.</i></p>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Section 4 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Hur ska underlaget för en situationsplan se ut?
                </AccordionSummary>
                <AccordionDetails>
                    <div id='section-4'>
                        <div className='faq-section'>
                            <ul>
                                <li>
                                    <h6>Underlag för situationsplan ska vara i kartformat med återsamlingsplats samt aktuell byggnad / byggnadsdel tydligt markerade.<br />
                                        Underlag i satellitformat accepteras ej då de medför större bearbetning. </h6>
                                </li>
                                <li>
                                    <h6>Området för situationsplanen ska begränsas till det aktuella närområdet.</h6>
                                </li>
                            </ul>
                        </div>

                        
                        <div className='image-wrapper'>
                            <div className='img-desc'>
                                <img src='/faq/q4_l.jpg' alt='Inskickat underlag'/>
                                <p>Inskickat underlag</p>
                            </div>
                            <div className='img-desc'>
                                <img src='/faq/q4_r.jpg' alt='Slutprodukt'/>
                                <p>Slutprodukt</p>
                            </div>
                        </div>

                        <p><i>Situationsplanen kommer förenklas så mycket som möjligt för att tydliggöra återsamlingsplatsens position i förhållande till den aktuella byggnaden. Byggnader och vägar bortanför återsamlingsplatsen kommer att skäras bort.</i></p>
                        <p>PDF: <a href={'/documents/instruktion_situationsplan.pdf'} target='_blank' rel='noreferrer'>Fullständiga instruktioner underlag till situationsplaner</a></p>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Section 5 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Kan jag ta med kundens logga på utrymningsplanen?
                </AccordionSummary>
                <AccordionDetails>
                    <div id='section-5'>
                        Ja! Bifoga aktuell logga som bildfil direkt i din beställning.<br />
                        Ett foto av en logga accepteras inte. 
                        <img src='/faq/q5.svg' alt='Exempel logga'/>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Section 6 */}
            <Accordion>
                <AccordionSummary className='section-header' expandIcon={<ExpandMoreIcon />}>
                    Kan jag fotografera av mitt underlag?
                </AccordionSummary>
                <AccordionDetails>
                <div id='section-6'>
                    <p>Ja! Tänk på följande vid fotografering av underlag:</p> 
                    <ul>
                        <li>Ritningen ligger platt och jämnt mot bordsytan</li>
                        <li>Fotot tas rakt ovanifrån </li>
                        <li>Ritningens mittpunkt är centrerad i bild</li>
                        <li>Fotot tas inte genom ett plexiglas</li>
                    </ul>
                    <p><i>Det finns en del gratisappar man kan kan ta till hjälp för att fotografiet skall bli helt rakt, exempelvis Adobe Scan. Kan vara värt att testa!</i></p>
                    <p>Det här är en illustration av hur vårt arbete försvåras av fotograferade underlag i perspektiv. </p>
                    <div className='image-wrapper'>
                        <div className='img-row'>
                            <div className='img-desc'>
                                <p><b>Inskickat underlag</b></p>
                                <img src='/faq/q6_l_1.jpg' alt='Inskickat underlag'/>
                            </div>

                            <div className='img-desc'>
                                <p><b>Slutprodukt</b></p>
                                <img src='/faq/q6_r_1.svg' alt='Slutprodukt'/>
                            </div>
                        </div>

                        <div className='img-row'>
                        <div className='img-desc'>
                                <p></p>
                                <img src='/faq/q6_l_2.jpg' alt='Inskickat underlag'/>
                            </div>

                            <div className='img-desc'>
                                <p></p>
                                <img src='/faq/q6_r_2.svg' alt='Slutprodukt'/>
                            </div>
                        </div>
                    </div>
                </div>

                <p id='link'>PDF: <a href={'/documents/instruktion_fotograferat_underlag.pdf'} target='_blank' rel='noreferrer'>Fullständiga instruktioner fotografering av underlag</a></p>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

export { FaqSection };