import {verifyUserEmailUrl} from "./config"
import {submitOrderUrl} from "./config"

const requestOptions = (data) => {
  return {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };
}

export const verifyUserEmail = (email) => {
  return fetch(verifyUserEmailUrl(), requestOptions({ "clientEmail": email }))
    .then((response) => {
      
      if (response.status === 200) {
        return true;
      } else if (response.status === 404) {
        return false;
      }
    })
    .catch((error) => {
      console.log("An error occurred during email verification:", error);
      return false;
    });
};

const multipartRequestOptions = (data, files) => {
  console.log(data);
  console.log(files);

  const formData = new FormData();
  formData.append('jsonData', JSON.stringify(data));

  files.forEach((file) => {
    formData.append('files', file);  // Change made here
  });

  return {
    method: 'POST',
    body: formData
  };
}


export const submitOrder = (formValues, files) => {
  console.log(formValues);  

  return fetch(submitOrderUrl(), multipartRequestOptions(formValues, files))
    .then((response) => {
      if (response.status === 200) {
        return 0;
      } else if (response.status === 404) {
        return 1;
      } else {
        console.log("Unexpected response status:", response.status);
        return -1;
      }
    })
    .catch((error) => {
      console.log("An error occurred during form submission:", error);
      return -1;
    });
};

