export const FILES_SIZE_LIMIT = 500000000;
export const FILES_AMOUNT_LIMIT = 20;
export const REQUIRED_ERROR_MSG = "Det här fältet är obligatoriskt";
export const EMAIL_ERROR_MSG = "Ogiltig email";
export const PHONE_NUMBER_ERROR_MSG = "Telefonnummer måste bestå av siffror";
export const POST_NUMBER_ERROR_MSG = "Postnummer måste bestå av siffror";
export const ORDER_ITEM_ERROR_MSG = "Produkten måste ha rätt typ, storlek, leveranssätt och antal";
export const SUCCESS = "Tack för din beställning! \nEn kopia på din order har skickats till "
export const ERROR_FIELD_VALIDATION = "Inmatningsverifikation misslyckades, ett eller flera tvingande fälts har inte fyllts i korrekt."
export const ERROR_GENERAL = "Något gick fel med kommunikationen till servern. Var god att ladda om websidan och försök igen."
export const ERROR_INVALID_EMAIL = "Detta orderformulär är endast för våra registrerade kunder, kontakta oss för att bli tillagd i vårt system."
export const ERROR_BOX_ADDRESS = "Vi kan ej leverera till box-adresser."