import './OrderForm.css';
import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import FormHelperText from '@mui/material/FormHelperText';
import { FILES_SIZE_LIMIT, FILES_AMOUNT_LIMIT} from '../../const';

function UploadFiles({uploadedFiles, setUploadedFiles, submitted}) {
 
    const [filesSize, setFilesSize] = useState(0);
    const [errorText, setErrorText] = useState('');
    
    useEffect(() => {
        const isValid = uploadedFiles.length !== 0;
        if(!isValid && submitted){
            setErrorText("Minst en fil krävs");
        }else{
            setErrorText('');
        }
      }, [uploadedFiles, submitted]);


    const handleUploadFiles = (files) => {
        const uploaded = uploadedFiles ? [...uploadedFiles] : [];
        files.forEach((file) => {
            if (uploaded.findIndex((f) => f.name === file.name) === -1) {
                if (filesSize + file.size <= FILES_SIZE_LIMIT) {
                    if(uploadedFiles.length > (FILES_AMOUNT_LIMIT - 1)){
                        alert("Max antal filer uppnådd");
                    } else {
                        uploaded.push(file);
                        setFilesSize(filesSize + file.size);
                    }
                } else {
                    alert("Filstorleken överskrider 500 MB");
                }
            }
        });
        setUploadedFiles(uploaded);
    }
 
    const handleFileEvent = (files) => {
        if (files && files.length > 0) {
            const chosenFiles = Array.from(files);
            handleUploadFiles(chosenFiles);
        }
    }
    

    const handleRemoveFile = (file) => {
        const newList = uploadedFiles.filter((item) => item.name !== file.name);
        setFilesSize(filesSize - file.size);
        setUploadedFiles(newList);
    }

     // drag state
     const [dragActive, setDragActive] = useState(false);
     // ref
     const inputRef = useRef(null);
     
     // handle drag events
     const handleDrag = function(e) {
         e.preventDefault();
         e.stopPropagation();
         if (e.type === "dragenter" || e.type === "dragover") {
            setDragActive(true);
         } else if (e.type === "dragleave") {
            setDragActive(false);
         }
     };
     
     // triggers when file is dropped
     const handleDrop = function(e) {
         e.preventDefault();
         e.stopPropagation();
         setDragActive(false);
         if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            handleFileEvent(e.dataTransfer.files)
            e.target.value=null
         }
     };
     
     // triggers the input when the button is clicked
     const onButtonClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
         inputRef.current.click();
     };

    return (
        <div>
            <div id="form-file-upload" onDragEnter={handleDrag}>
                <input ref={inputRef} onSubmit={(e) => {e.preventDefault()}} name="file" type="file" id="input-file-upload" required={uploadedFiles.length === 0} multiple={true} onChange={e => {
                    handleFileEvent(e.target.files)
                }}/>
                <label id="label-file-upload" htmlFor="input-file-upload" className={dragActive ? "drag-active" : "" }>
                    <div>
                    <button className="upload-button" onClick={onButtonClick}>Klicka här</button>
                    <span>att bläddra eller släpp filer här</span>
                    </div> 
                </label>
                { dragActive && <div id="drag-file-element" onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div> }
            </div> 
 
            {!!errorText &&
                <div style={{marginBottom: '10px'}}>
                    <FormHelperText error={true} style={{fontSize: '16px', textAlign: 'center'}}>{errorText}</FormHelperText>
                </div>
            }

            <div className='mb-3'>
                {uploadedFiles.map(file => (
                    <div className='uploaded-files' key={file.name} onClick = {() => handleRemoveFile(file)}>
                        {file.name}
                        <p style={{float: 'right', borderStyle: 'none'}}>X</p>
                    </div>
                ))}
            </div>
        </div>
        
    );
}

export { UploadFiles };