import './OrderForm.css';
import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import ClearIcon from '@mui/icons-material/Clear';
import { ORDER_ITEM_ERROR_MSG } from '../../const';

function OrderType({ items, setItems, submitted, isValid, onBlur, setIsValidItems, isRev }) {
    const [errorText, setErrorText] = useState('');

    const handleAmountChange = (event, index) => {
        const { name, value } = event.target;
        const updatedItems = [...items];
        updatedItems[index][name] = Number(value);
        setItems(updatedItems);
    };

    useEffect(() => {
        if(!isValid){
            setErrorText(ORDER_ITEM_ERROR_MSG);
        }else {
            setErrorText('');
        }
    }, [isValid, submitted])

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        const updatedItems = [...items];
        if (name === "evacuationPlanSize") {
            if (value === "A1" || value === "A2") {
                if (updatedItems[index]["evacuationPlanDelivery"] === "Print & Laminering") {
                    updatedItems[index]["evacuationPlanDelivery"] = "";
                }
            }
        }
        updatedItems[index][name] = value;
        setItems(updatedItems);
    };

    const handleAddItem = () => {
        setItems([...items, { evacuationPlanType: '', evacuationPlanSize: '', evacuationPlanDelivery: '', evacuationPlanQuantity: 0 }]);
    }

    const handleRemoveItem = (index) => {
        const newItems = [...items];
        newItems.splice(index, 1);
        setItems(newItems);
        verifyItems(newItems);
    };

    const verifyItems = (newItems) => {
        const hasEmptyOrWrongValues = newItems.length === 0 || newItems.some(
            (item) => (item.evacuationPlanType === '' || item.evacuationPlanSize === '' || item.evacuationPlanDelivery === '' || 
                (isNaN(item.evacuationPlanQuantity) || item.evacuationPlanQuantity <= 0 || item.evacuationPlanQuantity === '')) &&
                !(newItems.length > 1 && (item.evacuationPlanType === '' && item.evacuationPlanSize === '' && item.evacuationPlanDelivery === '')))
        
         setIsValidItems(!hasEmptyOrWrongValues);
    }

    return (
        <div>
            {items.map((item, index) => (
                <div key={index}>
                    <div className='item-wrapper'>
                        <FormControl fullWidth>
                            <Select name="evacuationPlanType" value={item.evacuationPlanType} className={"select-type"} error={!!errorText} onChange={(event) => handleChange(event, index)}
                            inputProps={{
                                    onBlur
                                  }}>
                                <MenuItem value="2D">Utrymningsplan 2D</MenuItem>
                                <MenuItem value="3D">Utrymningsplan 3D</MenuItem>
                                <MenuItem value="Ren">Renritning</MenuItem>
                                <MenuItem value="BSR">Brandskyddsritning</MenuItem>
                                <MenuItem value="Avsök">Avsökningsritning</MenuItem>
                                
                                {isRev && <MenuItem disabled>-----------------------------</MenuItem>}
                                    
                                {isRev && <MenuItem value="Rev 2D">Rev Utrymningsplan 2D</MenuItem>}
                                {isRev && <MenuItem value="Rev 3D">Rev Utrymningsplan 3D</MenuItem>}
                                {isRev && <MenuItem value="Rev Ren">Rev Renritning</MenuItem>}
                                {isRev && <MenuItem value="Rev BSR">Rev Brandskyddsritning</MenuItem>}
                                {isRev && <MenuItem value="Rev Avsök">Rev Avsökningsritning</MenuItem>}
                                
                            </Select>
                            <FormHelperText>Typ</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth>
                            <Select name="evacuationPlanSize" value={item.evacuationPlanSize} className={"select-type"} error={!!errorText} onChange={(event) => handleChange(event, index)}
                            inputProps={{
                                    onBlur
                                  }}>
                                <MenuItem value="A1">A1</MenuItem>
                                <MenuItem value="A2">A2</MenuItem>
                                <MenuItem value="A3">A3</MenuItem>
                                <MenuItem value="A4">A4</MenuItem>
                            </Select>
                            <FormHelperText>Storlek</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth >
                            <Select name="evacuationPlanDelivery" value={item.evacuationPlanDelivery} className={"select-type"} error={!!errorText} onChange={(event) => handleChange(event, index)} 
                            inputProps={{
                                    onBlur
                                  }}>
                                <MenuItem value="PDF">PDF</MenuItem>
                                <MenuItem value="Print">Print</MenuItem>
                                <MenuItem value="Print & Ram">Print & ram</MenuItem>
                                <MenuItem value="Print & Laminering" disabled={item.evacuationPlanSize==="A1" || item.evacuationPlanSize==="A2"}>Print & laminering</MenuItem>
                            </Select>
                            <FormHelperText>Leverans</FormHelperText>
                        </FormControl>

                        <div className='item-wrapper-quantity'>
                        <FormControl>
                            <TextField 
                                name="evacuationPlanQuantity"
                                value={item.evacuationPlanQuantity}
                                required={true}
                                error={!!errorText}
                                onChange={(event) => handleAmountChange(event, index)}
                                inputProps={{
                                    onBlur
                                  }}
                            />
                            <FormHelperText>Antal</FormHelperText>
                        </FormControl>
                        {items.length !== 1
                            ? <ClearIcon onClick={() => handleRemoveItem(index)} className='remove-item-symbol'/>
                            : <ClearIcon className='remove-item-symbol-disabled'/>
                        }
                        </div>
                    </div>
                </div>
            ))}

            {!!errorText &&
                <div style={{marginBottom: '10px'}}>
                    <FormHelperText error={true}>{errorText}</FormHelperText>
                </div>
            }

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '10px'}}> 
                <Button variant="success" onClick={handleAddItem}>
                    Lägg till fler produkter
                </Button>
            </div>
        </div>
    );
}

export { OrderType };