import './Footer.css';

function Footer() {
    return (
        <div className="footer-section">
            <div className='footer-details'>
                <span>SAFEXIT INFOMEDIA AB</span>
                <span>031 - 51 18 00</span>
            </div>
            <div className='footer-details'>
                <span>Stora Åvägen 21</span>
                <span><a style={{color: "#085908"}} href="mailto:info@safexit.se">info@safexit.se</a></span>
            </div>
            <div>
                <span>436 34 ASKIM</span>
            </div>
        </div>
    );
}

export { Footer };