import './Intro.css';
import logo from "../../assets/logo.png";

function Intro() {
    return (
        <div className="intro-section">
            <img src={logo} className={"logo"} alt="logo"/>
            <p className={'intro-text'}>Beställning av utrymningsplaner för våra samarbetspartners. Alla andra ritningar, <a style={{color: "#085908"}} href="mailto:order@safexit.se">mejla oss</a>.</p>
        </div>
    );
}

export { Intro };