import './OrderForm.css';
import { useState, useEffect } from 'react';
import {submitOrder} from '../../services/orderService';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { SectionHeader } from './SectionHeader';
import { FormGroup } from './FormGroup';
import { OrderType } from './OrderType';
import { UploadFiles } from './UploadFiles';
import { FaqSection } from './FaqSection';
import TextareaAutosize from '@mui/base/TextareaAutosize';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Checkbox from '@mui/material/Checkbox';
import {verifyUserEmail} from '../../services/orderService';
import { SUCCESS, ERROR_FIELD_VALIDATION, ERROR_GENERAL } from '../../const';


function OrderForm() {

    const defaultFormValues = {
        clientName: '',
        clientEmail: '',
        clientPhone: '',
        companyName: '',
        companyAddress: '',
        companyPostNumber: 0,
        companyCity: '',
        revOrderNumber: '',
        orderName: '',
        orderAddress: '',
        orderCity: '',
        orderInvoiceRef: '',
        reassemblySiteSE: '',
        reassemblySiteEN: '',
        items: [{ evacuationPlanType: '', evacuationPlanSize: '', evacuationPlanDelivery: '', evacuationPlanQuantity: 0 }],
        isRevision: false,
        notes: '',
        isDeliveryAddressSame: true,
        deliveryAddress: {
            companyName: '',
		    streetAddress: '',
		    postNumber: '',
            city: '',
		    contactPerson: '',
		    phoneNumber: ''
        }
    };

    const [formValues, setFormValues] = useState(() => {
        const storedFormValues = localStorage.getItem('formValues');
        return storedFormValues ? JSON.parse(storedFormValues) : defaultFormValues;
    });

    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isFormChecked, setIsFormChecked] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [deliveryChecked, setDeliveryChecked] = useState(false);
    const [isRevisionChecked, setIsRevisionChecked] = useState(false);
    const [isUtrymningsplanChecked, setIsUtrymningsplanChecked] = useState(false);
    const [orderTypeItems, setOrderTypeItems] = useState(formValues.items);

    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPhone, setIsValidPhone] = useState(true);
    const [isValidPostNumber, setIsValidPostNumber] = useState(true);
    const [isValidItems, setIsValidItems] = useState(true);
    const [isValidDeliveryPhone, setIsValidDeliveryPhone] = useState(true);
    const [isValidDeliveryPostNumber, setIsValidDeliveryPostNumber] = useState(true);
    const [isValidDeliveryStreetAddress, setIsValidDeliveryStreetAddress] = useState(true);

    const [isLoading, setIsLoading] = useState(false);
    const [showError, setShowError] = useState(false);

    // Read data from local storage
    useEffect(() => {
        window.scrollTo(0, 0);
        const storedFormValues = localStorage.getItem('formValues');
        if (storedFormValues) {
            setFormValues(JSON.parse(storedFormValues));
            checkCustomFieldsErrors();
        }
        setIsRevisionChecked(formValues.isRevision);
        setDeliveryChecked(!formValues.isDeliveryAddressSame);
    }, []);

    const checkCustomFieldsErrors = () => {
        const email = formValues.clientEmail;
        const phone = formValues.clientPhone;
        const postNumber = formValues.companyPostNumber;
        const deliveryPhone = formValues.deliveryAddress.phoneNumber;
        const deliveryPostNumber = formValues.deliveryAddress.postNumber;
        const deliverystreetAddress = formValues.deliveryAddress.streetAddress;
        const quantity = formValues.deliveryAddress.quantity;
    
        verifyIsEmailWhitelisted(email);
        verifyPhone(phone);
        verifyPostNumber(postNumber);
        if(deliveryChecked){
            verifyDeliveryPhone(deliveryPhone);
            verifyDeliveryPostNumber(deliveryPostNumber);
            verifyDeliveryStreetAddress(deliverystreetAddress);
        }
        verifyItems(quantity);
    }

    useEffect(() => {
        localStorage.setItem('formValues', JSON.stringify(formValues));
    }, [formValues]);
    
    useEffect(() => {
        const updatedItems = orderTypeItems.map(item => ({
            evacuationPlanType: item.evacuationPlanType,
            evacuationPlanSize: item.evacuationPlanSize,
            evacuationPlanDelivery: item.evacuationPlanDelivery,
            evacuationPlanQuantity: item.evacuationPlanQuantity
          }));

        setIsUtrymningsplanChecked(updatedItems.some(item => item.evacuationPlanType==='2D' || item.evacuationPlanType==='3D'  || item.evacuationPlanType==='Rev 2D'  || item.evacuationPlanType==='Rev 3D'));

        setFormValues(prevFormValues => ({
            ...prevFormValues,
            items: updatedItems,
        }));
    }, [orderTypeItems]);

    const scrollToError = (event) => {
        const errorElement = event.target.parentElement.querySelector('.Mui-error');
        if(errorElement){
            errorElement.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
        } else {
            window.scrollTo(0, 0);
        }
    }

    async function handleSubmit(event){
        setIsLoading(true);
        const form = event.currentTarget;
        checkCustomFieldsErrors();
        event.preventDefault();
        setIsSubmitted(true);
        if (form.checkValidity() === false || uploadedFiles.length < 1 || !isFormChecked || !(await verifyIsEmailWhitelisted(formValues.clientEmail)) || !isValidPhone || !isValidPostNumber || !isValidItems || (deliveryChecked && (!isValidDeliveryPhone || !isValidDeliveryPostNumber || !isValidDeliveryStreetAddress))) {
            event.stopPropagation();
            alert(ERROR_FIELD_VALIDATION);
            setTimeout(function(){
                scrollToError(event);
            }, 100);
            setShowError(true);
        } else {
            // Remove empty items
            const updatedItems = formValues.items.filter(item => item.evacuationPlanType !== '' || item.evacuationPlanSize !== '' || item.evacuationPlanDelivery !== '');
            const updatedFormValues = {
                ...formValues,
                items: updatedItems
            };
            setFormValues(updatedFormValues);
            
            const res = await submitOrder(updatedFormValues, uploadedFiles);
            if(res === 0){
                alert(SUCCESS + formValues.clientEmail);
                setShowError(false);
                clearValues();
            } else if (res === 1) {
                event.stopPropagation();
                scrollToError(event);
                setIsValidEmail(false);
            } else {
                event.stopPropagation();
                alert(ERROR_GENERAL);
            }
            window.scrollTo(0, 0);
        }
        setIsLoading(false);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'deliveryphoneNumber') {
            setFormValues({...formValues, deliveryAddress: { ...formValues.deliveryAddress, "phoneNumber": value.replace(/[-\s]/g, '')}});
        } else if (name.startsWith('delivery')){
            setFormValues({...formValues, deliveryAddress: { ...formValues.deliveryAddress,[name.substr(8)]: value}});

        } else if (name === 'clientPhone'){
            setFormValues({...formValues, "clientPhone": value.replace(/[-\s]/g, '')});
        } 
        else {
            setFormValues({...formValues, [name]: value });
        }
    };

    const handleOnBlur = (event) => {
        const { name, value } = event.target;

        if (name === 'clientEmail') {
            verifyIsEmailWhitelisted(value);
        }
        else if (name === "clientPhone") {
            verifyPhone(value);
        }
        else if (name === "deliveryphoneNumber"){
            verifyDeliveryPhone(value);
        }
        else if (name === "companyPostNumber") {
            verifyPostNumber(value);
        } 
        else if (name === "deliverypostNumber") {
            verifyDeliveryPostNumber(value);
        }
        else if(name === "deliverystreetAddress"){
            verifyDeliveryStreetAddress(value);
        }
        else if (name === "evacuationPlanQuantity" || name === "evacuationPlanSize" || name === "evacuationPlanType" || name === "evacuationPlanDelivery" ){
            verifyItems();
        }
    }
    
    const verifyIsEmailWhitelisted = async (email) => {
        if (email === '') {
            setIsValidEmail(false);
            return false;
        }
      
        const isValid = await verifyUserEmail(email);
      
        if (isValid) {
            setIsValidEmail(true);
            return true;
        } else {
            setIsValidEmail(false);
            return false;
        }
      };
    
    const verifyPhone = (value) => {
        value === '' || setIsValidPhone(/^[+]?[\d -]+$/.test(value));
    }

    const verifyDeliveryPhone = (value) => {
        value === '' || setIsValidDeliveryPhone(/^[+]?[\d -]+$/.test(value));
    }

    const verifyPostNumber = (value) => {
        value === '' || setIsValidPostNumber(/^[0-9 -]+$/.test(value));
    }

    const verifyDeliveryPostNumber = (value) => {
        value === '' || setIsValidDeliveryPostNumber(/^[0-9 -]+$/.test(value));
    }

    const verifyDeliveryStreetAddress = (value) => {
        value === '' || setIsValidDeliveryStreetAddress(!value.toLowerCase().split(' ').includes('box'));
    }

    const verifyItems = () => {
        const hasEmptyOrWrongValues = formValues.items.length === 0 || formValues.items.some(
            (item) => (item.evacuationPlanType === '' || item.evacuationPlanSize === '' || item.evacuationPlanDelivery === '' || 
                (isNaN(item.evacuationPlanQuantity) || item.evacuationPlanQuantity <= 0 || item.evacuationPlanQuantity === '')) &&
                !(formValues.items.length > 1 && (item.evacuationPlanType === '' && item.evacuationPlanSize === '' && item.evacuationPlanDelivery === '')))

        setIsValidItems(!hasEmptyOrWrongValues);
    }

    const cleanFormValues = () => {
        if(window.confirm("Vill du rensa orderinformation?")){
            clearValues();
        }
    }

    const clearValues = () => {
        for(const key in formValues){
            if(!key.startsWith('client') && !key.startsWith('company') && !(key === 'deliveryAddress') && !(key === 'items')){
                formValues[key] = '';
            } 
            else if(key === 'deliveryAddress'){
                for(const deliveryKey in formValues.deliveryAddress){
                    formValues.deliveryAddress[deliveryKey] = ''
                }
            }
            else if(key === 'items'){
                formValues.items = []
            }
        }
        formValues.isDeliveryAddressSame = true;
        formValues.items = [{ evacuationPlanType: '', evacuationPlanSize: '', evacuationPlanDelivery: '', evacuationPlanQuantity: 0 }]
        localStorage.setItem('formValues', JSON.stringify(formValues));
        setFormValues(formValues);
        window.location.reload(false);
    }

    return (
        <div>
            <Form noValidate onSubmit={handleSubmit}>
                <div className="form-section">
                    <div className='form-error-msg' style={showError ? {} : {display: 'none'}}>{ ERROR_FIELD_VALIDATION }</div>
                    <SectionHeader title={"Kundinformation"}/>
                    <h4>Företag</h4>
                    <FormGroup label={'Namn*'} name={'companyName'} value={formValues.companyName} onChange={handleChange} submitted={isSubmitted}/>
                    <FormGroup label={'Gatuadress*'} name={'companyAddress'} value={formValues.companyAddress} onChange={handleChange} submitted={isSubmitted}/>
                    <FormGroup label={'Post nr*'} name={'companyPostNumber'} value={formValues.companyPostNumber === 0 ? '' : formValues.companyPostNumber} onChange={handleChange} submitted={isSubmitted} isValid={isValidPostNumber} onBlur={handleOnBlur}/>
                    <FormGroup label={'Ort*'} name={'companyCity'} value={formValues.companyCity} onChange={handleChange} submitted={isSubmitted}/>

                    <h4>Beställare</h4>
                    <FormGroup label={'Namn*'} name={'clientName'} value={formValues.clientName} onChange={handleChange} submitted={isSubmitted}/>
                    <FormGroup label={'Telefon nr*'} name={'clientPhone'} value={formValues.clientPhone} onChange={handleChange} submitted={isSubmitted} isValid={isValidPhone} onBlur={handleOnBlur}/>
                    <FormGroup label={'Email*'} name={'clientEmail'} value={formValues.clientEmail} onChange={handleChange} submitted={isSubmitted} isValid={isValidEmail} onBlur={handleOnBlur}/>
                    
                </div>

                <div className="form-section">
                    <div className="button-wrapper">
                        <Button variant="primary" tabIndex={-1} className="custom-button" onClick={() => cleanFormValues()}>
                            Rensa orderinformation
                        </Button> 
                    </div>
                    <SectionHeader title={"Orderinformation"}/>
                    <Form.Group>
                        <FormControlLabel control={<Checkbox checked={isRevisionChecked} onChange={(e) => {
                            setIsRevisionChecked(e.target.checked); 
                            setFormValues({...formValues, "isRevision": e.target.checked })
                        }}/>} label={<span>Revidering av tidigare Safexit-order</span>}/>
                    </Form.Group>
                    <div style={{display: !isRevisionChecked ? 'none' : 'block'}}>
                        <FormGroup label={'ID-nr från tidigare order*'} name={'revOrderNumber'} value={formValues.revOrderNumber} onChange={handleChange} submitted={isSubmitted} isRequired={isRevisionChecked}/>
                    </div>
                    <FormGroup label={'Objektsnamn*'} name={'orderName'} value={formValues.orderName} onChange={handleChange} submitted={isSubmitted}/>
                    <FormGroup label={'Gatuadress'} name={'orderAddress'} value={formValues.orderAddress} onChange={handleChange} submitted={isSubmitted} isRequired={false}/>
                    <FormGroup label={'Ort'} name={'orderCity'} value={formValues.orderCity} onChange={handleChange} submitted={isSubmitted} isRequired={false}/>
                    <FormGroup label={'Faktura referens/ Projekt nr'} name={'orderInvoiceRef'} value={formValues.orderInvoiceRef} onChange={handleChange} submitted={isSubmitted} isRequired={false}/>
                </div>
                
                <div className="form-section">
                    <SectionHeader title={"Typ av ritning"}/>
                    <OrderType items={orderTypeItems} setItems={setOrderTypeItems} submitted={isSubmitted} onBlur={handleOnBlur} isValid={isValidItems} setIsValidItems={setIsValidItems} isRev={isRevisionChecked}/>
                </div>
                
                {isUtrymningsplanChecked && (
                    <div className="form-section">
                        <SectionHeader title={"Återsamlingsplats"}/>
                        <FormGroup label={'Återsamlingsplats på Svenska*'} name={'reassemblySiteSE'} value={formValues.reassemblySiteSE} onChange={handleChange} submitted={isSubmitted}/>
                        <FormGroup label={'Återsamlingsplats på Engelska*'} name={'reassemblySiteEN'} value={formValues.reassemblySiteEN} onChange={handleChange} submitted={isSubmitted}/>
                    </div>
                )}
       
                <div className="form-section">
                    <SectionHeader title={"Anteckningar"}/>
                    <Form.Group className="mb-3">
                        <Form.Label>Övrigt</Form.Label>
                        <br/>
                        <TextareaAutosize style={{backgroundImage: 'none', border: '1px solid #ced4da'}} className="form-control" rows="5" value={formValues.notes} onChange={(e) => setFormValues({...formValues, "notes": e.target.value })}/>
                    </Form.Group>
                    <SectionHeader title={"Underlagsfiler"}/>
                    <UploadFiles uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} submitted={isSubmitted}/>
                    
                    <SectionHeader title={"Vanliga frågor gällande utrymningsplaner"}/>
                    <FaqSection/>
                </div>
                
                <div className="form-section">
                    <SectionHeader title={"Leverans"}/>
                    <Form.Group>
                        <FormControlLabel control={<Checkbox checked={deliveryChecked} onChange={(e) => {
                            setDeliveryChecked(e.target.checked); 
                            setFormValues({...formValues, "isDeliveryAddressSame": !e.target.checked })
                        }}/>} label={<span>Leverans av fysiska produkter till annan adress än din egna.</span>}/>
                    </Form.Group>
                    <div style={{display: !deliveryChecked ? 'none' : 'block'}}>
                        <SectionHeader title={"Leveransadress"}/>
                        <FormGroup label={'Företag / Namn*'} name={'deliverycompanyName'} value={formValues.deliveryAddress.companyName} onChange={handleChange} submitted={isSubmitted} isRequired={deliveryChecked}/>
                        <FormGroup label={'Gatuadress*'} name={'deliverystreetAddress'} value={formValues.deliveryAddress.streetAddress} onChange={handleChange} submitted={isSubmitted} isRequired={deliveryChecked} onBlur={handleOnBlur} isValid={isValidDeliveryStreetAddress}/>
                        <FormGroup label={'Post nr*'} name={'deliverypostNumber'} value={formValues.deliveryAddress.postNumber} onChange={handleChange} submitted={isSubmitted} isValid={isValidDeliveryPostNumber} onBlur={handleOnBlur} isRequired={deliveryChecked}/>
                        <FormGroup label={'Ort*'} name={'deliverycity'} value={formValues.deliveryAddress.city} onChange={handleChange} submitted={isSubmitted} isRequired={deliveryChecked}/>
                        <FormGroup label={'Kontaktperson'} name={'deliverycontactPerson'} value={formValues.deliveryAddress.contactPerson} onChange={handleChange} submitted={isSubmitted} isRequired={false}/>
                        <FormGroup label={'Telefon nr till kontaktperson'} name={'deliveryphoneNumber'} value={formValues.deliveryAddress.phoneNumber} onChange={handleChange} submitted={isSubmitted} isValid={isValidDeliveryPhone} onBlur={handleOnBlur} isRequired={false}/>
                        <ul>
                            <li>Ej box-adresser</li>
                            <li>Notera att leverans av inramade produkter kräver kontaktperson samt telefon nr utav fraktbolagen</li>
                        </ul>
                    </div>
                </div>

                <div className="form-section">
                    <div className="information-section">
                        <p>Informationen inskrivet i detta formulär krypteras av din webbläsare och skickas till vår server för att bli omhändertaget av vårt team.</p>
                        <Form.Group className='mb-2'>
                            <FormControlLabel required control={<Checkbox checked={isFormChecked} onChange={(e)=>setIsFormChecked(e.target.checked)}/>} label={<span>Jag godkänner att Safexit får handhava informationen ovan.</span>}/>
                            {isSubmitted && !isFormChecked && (
                                <FormHelperText error={true}>Du måste godkänna för att gå vidare.</FormHelperText>
                            )}
                        </Form.Group>
                    </div>
                    <div style={{textAlign: 'center'}}>
                        <Button variant="primary" className="custom-button" type="submit" disabled={isLoading}>
                            {isLoading ? "Skickar..." : "Skicka"}
                        </Button>
                    </div>
                </div>

                
            </Form> 
        </div>
    );
}

export { OrderForm };

